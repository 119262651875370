import React from 'react';


class IconStar extends React.PureComponent {

  render() {

    return (
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.26 15.56" width="10px" height="10px">
        <path d="M17.86,7.79c-.32-1-1.69-.86-3.27-.34a22.45,22.45,0,0,0-4,1.91,22.56,22.56,0,0,0,.56-4.44c0-1.67-.32-3-1.33-3s-1.35,1.33-1.34,3A22.55,22.55,0,0,0,9,9.36,22.23,22.23,0,0,0,5,7.45C3.38,6.93,2,6.83,1.7,7.79S2.55,9.48,4.14,10a22.08,22.08,0,0,0,4.4.83,22.27,22.27,0,0,0-3.07,3.26c-1,1.34-1.51,2.61-.69,3.21S6.65,17,7.63,15.65a22.43,22.43,0,0,0,2.15-3.94,21.79,21.79,0,0,0,2.15,3.94c1,1.35,2,2.24,2.84,1.64s.3-1.87-.68-3.21A22.82,22.82,0,0,0,11,10.82a22.08,22.08,0,0,0,4.4-.83C17,9.48,18.17,8.76,17.86,7.79Z" transform="translate(-1.65 -1.92)"/>
      </svg>
      );
  }
}

export default IconStar;
