import React from 'react';

import { SubTypo, MediumHeaderTypo } from '../commonStyles/typoTheme';

import { ProjectMainContainer, ProjectText, ProjectTextHalfTop, ProjectGlyph, ProjectNumber, ProjectTitle, ProjectSubtitle } from './styles/ProjectElementsStyle';

import ProjectImage from './ProjectImage';
import ProjectHoverText from './ProjectHoverText';
import IconStar from './IconStar';



class ProjectElement extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      isHover: false
     };
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onMouseEnter() {
    this.setState({ isHover: true });
  }

  onMouseLeave() {
    this.setState({ isHover: false });
  }

  render() {
    const {
      isLeft,
      bgImg,
      projectNumber,
      projectSubtitle,
      projectTitle
    } = this.props;
    const {
      isHover
    } = this.state;

    return (
      <ProjectMainContainer
        isLeft={isLeft}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <ProjectImage
          isLeft={isLeft}
          isHover={isHover}
          style={
            {
              backgroundImage: bgImg
            }
          }
        />
        <ProjectText isLeft={isLeft}>
          <ProjectTextHalfTop>
            <ProjectGlyph>
              <IconStar />
            </ProjectGlyph>
            <ProjectSubtitle isLeft={isLeft} isHover={isHover}>
              <SubTypo>
                {projectSubtitle}
              </SubTypo>
            </ProjectSubtitle>
            <ProjectNumber isLeft={isLeft}>
              <MediumHeaderTypo>
                {projectNumber}
              </MediumHeaderTypo>
            </ProjectNumber>
            <ProjectTitle isLeft={isLeft}>
              <MediumHeaderTypo>
                {projectTitle}
              </MediumHeaderTypo>
            </ProjectTitle>
            <ProjectHoverText isHover={isHover}/>
          </ProjectTextHalfTop>
        </ProjectText>
      </ProjectMainContainer>
      );
  }
}

export default ProjectElement;
