import React from 'react';

import ProjectElement from './ProjectElement';


import { SectionProjectMargin } from '../commonStyles/SectionsStyle';
import SectionProjectWrapper from './styles/SectionProjectWrapper';


class SectionProject extends React.PureComponent {
  render() {
      return (
        <SectionProjectWrapper href={this.props.url ? this.props.url : '/'}>
          <SectionProjectMargin isFeaturedProject={this.props.isFeaturedProject}>
            <ProjectElement isLeft={this.props.isLeft} projectNumber={this.props.projectNumber}  projectTitle={this.props.projectTitle} projectSubtitle={this.props.projectSubtitle} bgImg={this.props.bgImg} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} />
          </SectionProjectMargin>
        </SectionProjectWrapper>
      );
  }
}

export default SectionProject;
