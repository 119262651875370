import React from 'react';

import { ProjectImgStyle } from '../commonStyles/ImagesStyles';

class ProjectImage extends React.PureComponent {

  render() {

    const {
      isLeft,
      isHover,
      style
    } = this.props;

    return (
        <ProjectImgStyle
          style={style}
          isLeft={isLeft}
          isHover={isHover}
          >
        </ProjectImgStyle>
      );
  }
}

export default ProjectImage;
