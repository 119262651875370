import styled from "styled-components";


export const ProjectMainContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  cursor: pointer;

  /* 1366px */
  @media (min-width: 85.375em) {
    margin: 0;
  }
`;

export const ProjectText = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  float: left;
  color: ${ props => props.theme.color };

  /* 1024px */
  @media (min-width: 64em) {
    padding: ${ props => props.isLeft ? '0 0 0 6rem' : '0 6rem 0 0' };
  }


  /* 1366px */
  @media (min-width: 85.375em) {
    width: 50%;
    height: 80vh;
    max-height: 120rem;
  }
`;

export const ProjectTextHalfTop = styled.div`
  display: block;
  position: relative;
  margin: 4rem 0 0;
  padding-right: 0.5rem;

  /* 1366px */
  @media (min-width: 85.375em) {
    margin: 0;
    position: absolute;
    top:50%;
    transform: translate(0,-50%);
  }
`;


export const ProjectHoverTextContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  margin-top: 3rem;
  overflow: visible;
  height: 5rem;
  color: ${ props => props.theme.linkColor };

  /* 1024px */
  @media (min-width: 64em) {
    display: block;
    position: relative;
    width: 70%;
    margin-left: 30%;
    margin-top: 5rem;
    height: 5em;
    overflow: hidden;
  }
`;


export const ProjectSeeCaseStudy = styled.div`
  display: none;

  /* 1024px */
  @media (min-width: 64em) {
    display: block;
    position: absolute;
    width: 100%;
    top: ${ props => props.isHover ? '0' : '7rem' };
    transition: 300ms linear;
    height: 5rem;
  }

  /* 1366px */
  @media (min-width: 85.375em) {
    width: 100%;
  }
`;

export const ProjectClick = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  transition: 300ms linear;
  height: 6rem;

  /* 1024px */
  @media (min-width: 64em) {
    top: ${ props => props.isHover ? '-7rem' : '0' };
  }

  /* 1366px */
  @media (min-width: 85.375em) {
    width: 100%;
  }
`;


export const ProjectGlyph = styled.div`
  display: none;

  /* 1024px */
  @media (min-width: 64em) {
    display: inline-block;
    position: relative;
    width: 30%;
    float: left;
    margin-bottom: 6rem;
    color: ${ props => props.theme.secondaryColor };
  }
`;

export const ProjectSubtitle = styled.div`
  display: none;

  /* 1024px */
  @media (min-width: 64em) {
    display: inline-block;
    position: relative;
    width: 70%;
    float: left;
    margin-bottom: 6rem;
    color: ${ props => props.theme.secondaryColor };
  }
`;

export const ProjectNumber = styled.div`
  display: none;

  /* 1024px */
  @media (min-width: 64em) {
  display: inline-block;
  position: relative;
  width: 30%;
  float: left;
  }
`;

export const ProjectTitle = styled.div`
  display: block;
  width: 100%;
  height: fit-content;

  /* 1024px */
  @media (min-width: 64em) {
    display: inline-block;
    width: 70%;
  }
`;
