import React from 'react';

import { IconOuterCircle } from '../commonStyles/ImagesStyles';

class IconEyes extends React.PureComponent {

  render() {

    return (
    <IconOuterCircle>
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 17.5 15">
        <path d="M0,20" transform="translate(-1.25 -2.5)"/>
        <path d="M14.25,2.5c-2,0-3.65,2.07-4.25,5-.6-2.94-2.25-5-4.25-5-2.52,0-4.5,3.29-4.5,7.5s2,7.5,4.5,7.5c2,0,3.65-2.07,4.25-5,.6,2.94,2.25,5,4.25,5,2.52,0,4.5-3.29,4.5-7.5S16.77,2.5,14.25,2.5Zm-7.5,11A3,3,0,0,0,9,12.5c-.54,2.33-1.8,4-3.22,4-1.9,0-3.5-3-3.5-6.5s1.6-6.5,3.5-6.5c1.67,0,3.11,2.3,3.43,5.25A3,3,0,1,0,6.75,13.5Zm7.5,3c-1.9,0-3.5-3-3.5-6.5s1.6-6.5,3.5-6.5c1.67,0,3.11,2.3,3.43,5.25a3,3,0,1,0-.21,3.75C16.93,14.83,15.67,16.5,14.25,16.5Z" transform="translate(-1.25 -2.5)"/>
      </svg>
    </IconOuterCircle>
      );
  }
}

export default IconEyes;
