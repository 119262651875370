import React from 'react';

import { FixedElementsTypo } from '../commonStyles/typoTheme';

import { ProjectHoverTextContainer, ProjectClick, ProjectSeeCaseStudy } from './styles/ProjectElementsStyle';
import { InlineFloatIconText } from '../commonStyles/ImagesStyles';

import IconEyes from './IconEyes';
import IconArrow from './IconArrow';
class ProjectHoverText extends React.PureComponent {

  render() {
    const {
      isHover,
      isLeft
    } = this.props;
    return (
      <ProjectHoverTextContainer isHover={isHover} isLeft={isLeft}>
          <ProjectSeeCaseStudy isHover={isHover}>
            <InlineFloatIconText>
              <FixedElementsTypo>
                Click to read more
              </FixedElementsTypo>
            </InlineFloatIconText>
            <InlineFloatIconText>
              <IconArrow />
            </InlineFloatIconText>
          </ProjectSeeCaseStudy>
          <ProjectClick isHover={isHover}>
            <InlineFloatIconText>
              <FixedElementsTypo>
                See case study
              </FixedElementsTypo>
            </InlineFloatIconText>
            <InlineFloatIconText>
              <IconEyes />
            </InlineFloatIconText>
          </ProjectClick>
      </ProjectHoverTextContainer>
      );
  }
}

export default ProjectHoverText;
